import { BrowserRouter, Route, Routes } from 'react-router-dom'
import App from './App'
import { KPIDashboard } from './KPIDashboard/KPIDashboard'

const Root = () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route exact path='/' element={<App/>}/>
                <Route exact path='/fixrate-city' element={<App fixrateCity/>}/>
                <Route exact path='/fixrate-city/:office' element={<App fixrateCity/>}/>
                <Route exact path='/:dashboard' element={<App/>}/>
                <Route exact path='/:dashboard/:for' element={<App/>}/>
                <Route path='/kpi-dashboard' element={<KPIDashboard />} />
            </Routes>
        </BrowserRouter>
    )
}

export default Root