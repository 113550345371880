import styles from './FridaySummary.module.scss'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import {GET} from '../../utils/networkCallApi'
import coin from './assets/smw_coin.wav'

const FridaySummary = ({jwt, exitFn} : {jwt: string | null, exitFn: () => void}) => {
    const [marioIsJumping, setMarioIsJumping] = useState(false)
    const [luigiIsJumping, setLuigiIsJumping] = useState(false)
    const [currentDepositJumpVolume, setCurrentDepositJumpVolume] = useState(0)
    const [currentFundJumpVolume, setCurrentFundJumpVolume] = useState(0)
    const [weeklyDepositVolume, setWeeklyDepositVolume] = useState(0)
    const [weeklyFundVolume, setWeeklyFundVolume] = useState(0)
    const [startCounting, setStartCounting] = useState(false)

    const currentJumpVolume = currentDepositJumpVolume + currentFundJumpVolume

    const playCoinSound = () => {
        new Audio(coin).play()
    }

    useEffect(() => {
        GET('/api/stagg/volume/all', jwt).then(function (response) {
            setWeeklyDepositVolume(response.orderedLast7)
            console.log("deposits", response)
        })
        GET('/api/stagg/volume/funds', jwt).then(function (response) {
            setWeeklyFundVolume(response.orderedLast7)
            console.log("funds", response)
        })
    },[])

    const marioJump = () => {
        setMarioIsJumping(true)
        setTimeout(() => {
            playCoinSound()
        }, 500)
        setTimeout(() => {
            setCurrentDepositJumpVolume(prev => prev + 10)
            setMarioIsJumping(false)
        }, 1000)
    }

    const luigiJump = () => {
        setLuigiIsJumping(true)
        setTimeout(() => {
            playCoinSound()
        }, 500)
        setTimeout(() => {
            setCurrentFundJumpVolume(prev => prev + 10)
            setLuigiIsJumping(false)
        }, 1000)
    }

    useEffect(() => {
        if (weeklyDepositVolume !== 0 && startCounting) {
            let numberOfJumps = weeklyDepositVolume / 10;
            let currentJump = 1;
            const jumpInterval = setInterval(() => {
                if (numberOfJumps <= currentJump) {
                    clearInterval(jumpInterval)
                }
                if (currentJumpVolume === 0 && weeklyDepositVolume === 0) {
                    return
                }
                marioJump()

                currentJump = currentJump + 1;
            }, 2000)
            return () => clearInterval(jumpInterval)
        }
    },[weeklyDepositVolume, startCounting])

    useEffect(() => {
        setTimeout(() => {
            if (weeklyFundVolume !== 0 && startCounting) {
                let numberOfJumps = weeklyFundVolume / 10;
                let currentJump = 1;
                const jumpInterval = setInterval(() => {
                    if (numberOfJumps <= currentJump) {
                        clearInterval(jumpInterval)
                    }
                    if (currentJumpVolume === 0 && weeklyFundVolume === 0) {
                        return
                    }
                    luigiJump()

                    currentJump = currentJump + 1;
                }, 2000)
                return () => clearInterval(jumpInterval)
            }
        }, 500)
    },[weeklyFundVolume, startCounting])

    return (
        <div className={styles.fridaySummary}>
            <div className={styles.marioWorld}>
                <button onClick={() => exitFn()} className={styles.exitButton}>Avslutt</button>
                <div className={styles.textContainer}>
                    { startCounting && (
                        <>
                        <p>Denne uken har vi fått inn</p>
                        <h1>{currentJumpVolume} MILL.</h1>
                        <p>{currentDepositJumpVolume} i innskudd, {currentFundJumpVolume} i fond</p>
                        </>
                    )}
                    { !startCounting && (
                        <button className={styles.button} onClick={() => setStartCounting(true)}>Start opptelling!</button>
                    )}
                </div>
                <div className={styles.marioJumpWrapper}>
                    <div className={classNames(styles.coin, marioIsJumping && styles.show)}/>
                    <div className={styles.moneyBox}/>
                    <div className={classNames(styles.mario, marioIsJumping && styles.jumping)}></div>

                    <div className={classNames(styles.luigiCoin, luigiIsJumping && styles.show)}/>
                    <div className={styles.luigiMoneyBox}/>
                    <div className={classNames(styles.luigi, luigiIsJumping && styles.jumping)}></div>
                </div>
            </div>
        </div>
    )
}

export default FridaySummary