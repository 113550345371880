import { BankDto } from "../../types"
import styles from "../../app.module.scss"

export default function BankRow({banks, onClick} : {banks: BankDto[], onClick: (bank: BankDto) => void}) {
    return (
        <ul id="banks" className={styles.banks}>
            {banks.map((bank) => (
                <li
                    id={bank.id}
                    key={bank.id}
                    className={
                        bank.totalAssets > 5000
                            ? styles.big
                            : styles.small
                    }
                    onClick={() => onClick(bank)}
                >
                    <h3>{bank.name}</h3>
                    <img
                        alt=""
                        src={
                            bank.totalAssets > 5000
                                ? "/buildings/banks/Bank-big.svg"
                                : "/buildings/banks/Bank-small.svg"
                        }
                    />
                </li>
            ))}
        </ul>
    )
}