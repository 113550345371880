type calendarEvent = {
    name: string;
    days: number[];
    hour: number;
    minutes: number;
    person: {
        name: string;
        title: string;
    };
    message: string[];
    actions: {
        url: string;
        title: string;
    }[];
    speak: string;
    office: "TRONDHEIM" | "ALL"
};

export const calendarEvents:calendarEvent[] = [
    {
        name: 'Standup',
        office: 'TRONDHEIM',
        days: [1, 2, 3, 4, 5],
        hour: 10,
        minutes: 43,
        person: {
            name: 'Sigurd Stendal',
            title: 'CTO'
        },
        message: [
            'Hepp, hepp – opp og stå, nå er det Standup!',
            'Ny dag, nye trellosaker – foran skjermen...Nu!',
            'Det er rart hvordan Agile noen ganger er så lite smidig, men det kan vi snakke om etter standup',
            'For mannen med Trello-boardet ser alle problemer ut som et kort',
            'Hva er greia med...standup?',
        ],
        actions: [
            {
                url: 'msteams:/l/meetup-join/19:meeting_NTBlNDE5NjEtNGViNC00MzQwLWI5Y2EtMmY0M2E0YjdhN2Q2@thread.v2/0?context=%7b%22Tid%22%3a%221afb72f1-4a3c-4d39-bba4-7a1770f663d9%22%2c%22Oid%22%3a%22a6fd9012-2ffc-4b3a-bf04-e4f3655a55c3%22%7d&anon=true&deeplinkId=f188c932-c8ef-4ed7-996d-7137b1ad86cf&launchAgent=join_launcher&type=meetup-join&directDl=true&msLaunch=true&enableMobilePage=true&suppressPrompt=true&fqdn=teams.microsoft.com',
                title: 'Del med de på gjemmekontor'
            },
        ],
        speak: "Lets gather around the TV for our daily standup meeting."
    },
    {
        name: 'Lunsj',
        office: 'TRONDHEIM',
        days: [3, 4],
        hour: 11,
        minutes: 0,
        person: {
            name: 'Sigurd Stendal',
            title: 'CTO'
        },
        message: [
            'Hvordan kan det ha seg at alt jeg liker, fører til kreft hos mus?',
            'Det eneste jeg forlanger av mat, er at den ikke skader meg.',
            'En full mage kan lett faste.',
            'Spising uten konversering er bare stapping.',
            'Hadde vært digg å slippe rosinbonanza-gryta i dag!',
            'Vet ikke med dere, men magen min romler noe vanvittig!',
            'Kanskje det e fæsk i dag?',
            'Da er det dags for foring!',
            'Hymer lurer på hvordan NIBOR3M vil oppføre seg på et 1,5 måneders innskudd, men det får vi ta etter lunsj.',
            'Lite gøtt og grillat i dag kanskje?',
        ],
        actions: [],
        speak: ""
    },
    {
        name: 'Lunsj',
        office: 'TRONDHEIM',
        days: [1],
        hour: 11,
        minutes: 0,
        person: {
            name: 'Sigurd Stendal',
            title: 'CTO'
        },
        message: [
            'En grønnsaksrett med kjærlighet er bedre enn oksestek med hat sier de.',
            'Benjamin Franklin sa: "løk kan til og med få arvinger og enker til å gråte"',
            'Må vel få i oss noen grøntfor i dag!',
            'Håper det er veggisburger i dag!',
            'Halloumi er den eneste maten jeg vet om som gnikker. Er vi heldige så får vi sjekka ut det i dag!',
        ],
        actions: [],
        speak: ""
    },
    {
        name: 'Lunsj',
        office: 'TRONDHEIM',
        days: [2],
        hour: 11,
        minutes: 0,
        person: {
            name: 'Sigurd Stendal',
            title: 'CTO'
        },
        message: [
            'En skal ikke rose fisken før den er kommet på disken.',
            'Den fisken som slapp unna, var alltid stor! Nå er det fisk på menyen',
            'Kokken har vært ute med sjarken og henta opp noen saftige beist av noen fisk til oss',
            'Gratis behandling mot Struma i kantina i dag! Lets go',
            'Den som vil fange store fisker, må ha store kroker!',
        ],
        actions: [],
        speak: ""
    },
    {
        name: 'Lunsj',
        office: 'TRONDHEIM',
        days: [5],
        hour: 11,
        minutes: 0,
        person: {
            name: 'Sigurd Stendal',
            title: 'CTO'
        },
        message: [
            'Da blir det dobbel Taco-friday denne uka også!',
            'Ain’t no party like a taco party!',
            'Det er jo bare å knuse skjellet, så blir det Nachos!',
        ],
        actions: [],
        speak: ""
    },
    {
        name: 'Friday Wins',
        office: 'ALL',
        days: [5],
        hour: 12,
        minutes: 58,
        person: {
            name: 'Brynjar Ellingsen',
            title: 'CEO'
        },
        message: [
            'For en gjeng med vinnera dokk e! No e det Friday Wins!',
            'Fram med Twist-posen folkens, det bi og bi Friday Wins!',
            'Noen som har en Friday Wins demo som treffer mellom øra på resten av gjengen?',
            'Da e det klart for Friday Wins – gogogo!',
            'Et par tima til gullrekka, men no – Friday Wins!'
        ],
        actions: [
            {
                url: 'msteams:/l/meetup-join/19:meeting_MjQwMWQxMjAtZWZkYS00YjJlLWEwN2QtMDc2YzE2ZTE2NTdh@thread.v2/0?context=%7b%22Tid%22%3a%221afb72f1-4a3c-4d39-bba4-7a1770f663d9%22%2c%22Oid%22%3a%22ec70b175-2948-409c-81c9-d308bd70519f%22%7d&anon=true&deeplinkId=4139013b-3ea1-470a-ab76-cd4876688e50&launchAgent=join_launcher&type=meetup-join&directDl=true&msLaunch=true&enableMobilePage=true&fqdn=teams.microsoft.com',
                title: 'Bli med!'
            },
        ],
        speak: "It's Friday Wins already? Time flies when you're having fun!"
    },
]