import { format, previousDay } from "date-fns"
import { TrelloBoard, TrelloCard, TrelloList, TrelloMember } from "./types"

const PRODUCT_BOARD_ID = "58bd4c34fa55e442edd95c7b"

export const IGNORED_TRELLO_MEMBERS = [
    "Brynjar Ellingsen",
    "Kristin Sandstad",
    "Håvard Veie",
]

type ConnectToTrelloResponse = {
    connected: boolean
}

const disconnectFromTrello = (): ConnectToTrelloResponse => {
    window.Trello.deauthorize()
    return { connected: false }
}

const connectToTrello = async (): Promise<ConnectToTrelloResponse> => {
    return new Promise((resolve, reject) => {
        const authenticationSuccess = function () {
            console.log("Successful authentication")
            resolve({ connected: true })
        }

        const authenticationFailure = function () {
            console.log("Failed authentication")
            resolve({ connected: false })
        }

        if (window.Trello) {
            window.Trello.authorize({
                type: "popup",
                name: "Getting Started Application",
                scope: {
                    read: "true",
                    write: "false",
                },
                expiration: "never",
                success: authenticationSuccess,
                error: authenticationFailure,
            })
        } else {
            console.log("Trello library not loaded")
            resolve({ connected: false })
        }
    })
}

/*
const getAllBoards = () => {
    window.Trello.get('/members/me/boards', (boards) => {
        return boards
      })
}
*/

const getBoard = (boardId: string): Promise<TrelloBoard> => {
    return new Promise((resolve, reject) => {
        window.Trello.get(
            `/boards/${boardId}`,
            (board: TrelloBoard) => {
                resolve(board) // Resolve the board with the TrelloBoard type
            },
            (error) => {
                reject(error) // Handle errors
            }
        )
    })
}

const getBoardLists = async (boardId: string): Promise<TrelloList[]> => {
    return new Promise((resolve, reject) => {
        window.Trello.get(
            `/boards/${boardId}/lists`,
            (lists: TrelloList[]) => {
                resolve(lists) // Resolve the Promise with the lists data
            },
            (error: any) => {
                reject(error) // Reject the Promise if there's an error
            }
        )
    })
}

const getCardsInList = async (listId: string): Promise<TrelloCard[]> => {
    return new Promise((resolve, reject) => {
        window.Trello.get(
            `/lists/${listId}/cards`,
            (cards: TrelloCard[]) => {
                resolve(cards) // Resolve the Promise with the cards data
            },
            (error) => {
                console.error("Error fetching cards:", error)
                reject(error) // Reject the Promise if there's an error
            }
        )
    })
}

const getMembersInBoard = async (boardId: string): Promise<TrelloMember[]> => {
    return new Promise((resolve, reject) => {
        window.Trello.get(
            `/boards/${boardId}/members`,
            (members: TrelloMember[]) => {
                resolve(members)
            },
            (error) => {
                console.error("Error fetching members:", error)
                reject(error)
            }
        )
    })
}

const getMemberInfo = async (memberId: string): Promise<TrelloMember> => {
    return new Promise((resolve, reject) => {
        window.Trello.get(
            `/members/${memberId}`,
            (member: TrelloMember) => {
                resolve(member)
            },
            (error) => {
                console.error("Error fetching member info:", error)
                reject(error)
            }
        )
    })
}

const getProductBoardLists = async (): Promise<TrelloList[]> => {
    return getBoardLists(PRODUCT_BOARD_ID)
}

const getProductBoard = (): Promise<TrelloBoard> => {
    return getBoard(PRODUCT_BOARD_ID)
}

const getProductBoardMembers = async (): Promise<TrelloMember[]> => {
    return getMembersInBoard(PRODUCT_BOARD_ID)
}

const getNewlyReleasedCards = async(lists: TrelloList[]): Promise<TrelloCard[]> => {
    let releaseCards = []
    const today = format(new Date(), "yyyy-MM-dd")
    const yesterday = format(previousDay(new Date(), 1), "yyyy-MM-dd")
    const newlyReleasedCardsListIds = lists.filter((list) => list.name?.toLowerCase().includes(today || yesterday)).map((list) => list.id)
    newlyReleasedCardsListIds
        .map((listId) => getCardsInList(listId)
        .then((cards) => releaseCards = [...releaseCards, ...cards]))

    return releaseCards
}

const getAllActiveCardsOnBoard = async (): Promise<TrelloCard[]> => {
    const lists = await getBoardLists(PRODUCT_BOARD_ID)
    const listIds = lists.filter(list => !list.name.includes("🎉")).map((list) => list.id)

    const allCards = await Promise.all(
        listIds.map((listId) => getCardsInList(listId))
    )

    return allCards.flat()
}

const getAllCardsForMember = async (memberId: string): Promise<TrelloCard[]> => {
    return new Promise((resolve, reject) => {
        window.Trello.get(
            `/members/${memberId}/cards`,
            (cards: TrelloCard[]) => {
                resolve(cards)
            },
            (error) => {
                console.error("Error fetching member cards:", error)
                reject(error)
            }
        )
    })
}

export {
    connectToTrello,
    disconnectFromTrello,
    getProductBoard,
    getProductBoardLists,
    getCardsInList,
    getProductBoardMembers,
    getMemberInfo,
    getNewlyReleasedCards,
    getAllActiveCardsOnBoard,
    getAllCardsForMember
}
