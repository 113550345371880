
function postWledRequest(jsonData: object): Promise<Response | void> {
    return fetch('https://localhost/json/state', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(jsonData),
        }
    ).catch(() => console.log("Posting to WLED failed."))
}

export class LedLogo {

    public async startAlarm(): Promise<void> {
        await postWledRequest({
            playlist: {
                ps: [5],
                dur: [100],
                repeat: 1,
                end: 1,
            }
        })
    }

    public async startCelebration(): Promise<void> {
        await postWledRequest({
            playlist: {
                ps: [6],
                dur: [300],
                repeat: 1,
                end: 1,
            }
        })
    }
}