import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import '@fixrate/fixrate-analytics-widgets/dist/index.css'
import {widgets} from "./Widgets";
import {isAuthorized} from "../security";

const Dashboard = ({dashboard, searchParam = null, decodedJwt, jwt}) => {

    if(!isAuthorized(dashboard, decodedJwt)) {
        return null;
    }

    const widgetParams = () => {
        return {jwt, interactive: false} // todo fixme fetch depositorId or bankId from jwt
    };

    return (

            <Carousel
                dynamicHeight={true}
                interval={searchParam?.get('interval') || dashboard.interval}
                transitionTime={searchParam?.get('transitionTime') || dashboard.transitionTime}
                autoPlay={true}
                infiniteLoop={true}
                showThumbs={false}
                stopOnHover={false}>
                {dashboard.widgets.map(widget => {
                    return (
                        <div className="carouselItem" key={widget.name}>
                            {React.createElement(widgets[widget.name], widgetParams())}
                        </div>
                    )
                })}
            </Carousel>
    );
};

export default Dashboard