import { Box, Card, CardContent, CardHeader, IconButton } from '@mui/material'
import { PURPLE } from '../../../../colors/colors'

export const KPICard = ({ title, children }: { title: string; children?: React.ReactNode }) => {
    return (
        <Card sx={{ backgroundColor: PURPLE[900], color: 'white', padding: '1rem 2rem 1rem 2rem', borderRadius: '12px' }}>
            <CardHeader
                title={title}
                titleTypographyProps={{ textTransform: 'uppercase', fontWeight: '600', fontFamily: 'Montserrat' }}
                action={
                    <Box color={PURPLE[100]}>
                        <IconButton color='inherit'>
                            <i className='ri-fullscreen-line' />
                        </IconButton>
                    </Box>
                }
            />
            <CardContent>{children}</CardContent>
        </Card>
    )
}
