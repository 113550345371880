import {
    AllDepositsInterest,
    TurnoverByProduct,
    BusinessVolume,
    DepositDuration,
    DepthByInterestForNiborProducts,
    DepthByInterestForFixedProducts,
    Nibor,
    NiborAll,
    DepositInterestByBankSegment,
    DepositMarginByBankSegment,
    DepositMarginByProduct,
    AdInterestByBankSegment,
    AdInterestByProduct,
    AdMarginByBankSegment,
    AdMarginByProduct,
    DepthByBankSegment,
    DepthByProduct,
    DepositInterestByBankSegmentWithSsb,
    DepositInterestWithSsb,
    AdInterestWithMajorBanks,
    MeanDepthByBankSegment,
    DepositVolumeByBankSegment,
    MeanDepositVolumeByBankSegment,
    TopUsers,
    NorwayDepositorVolumeMap,
    NorwayBankVolumeMap,
    NumberOfCustomers,
    Volumes,
    Income,
    IncomeNow,
    NorwayExposureMap,
    DepositInterestByProductForDepositor,
    DepositInterestForDepositorWithSsb,
    DepositMarginByProductForDepositor,
    BusinessVolumeForBank,
    TurnoverByProductForBank,
    DepositInterestByProductForBank,
    DepositInterestForBank,
    DepositMarginByProductForBank,
    DepositMarginForBank,
    AdInterestForBank,
    AdMarginForBank,
    DepthForBank,
    DepositVolumeByBankAndBankSegment,
    NorwayCustomerLocationMap,
    ActiveOrders,
    FixrateGrowth,
    NumberOfDepositsPerCustomer,
    ActiveAdsCount,
    AdsCount,
    NumberOfBanks,
    DepositCoveragePercentage,
    Quotes,
    Commands,
    LoginCount,
    ActiveUsers,
    DepositDeviations
} from '@fixrate/fixrate-analytics-widgets'
import FixrateUniverseTest from '../widgets/FixrateUniverseTest'

export const widgets = {
    // Fixrate
    Commands,
    LoginCount,
    ActiveUsers,
    Quotes,
    Income,
    IncomeNow,
    NorwayBankVolumeMap,
    NorwayDepositorVolumeMap,
    NumberOfCustomers,
    Volumes,
    TopUsers,
    ActiveOrders,
    FixrateGrowth,
    NumberOfDepositsPerCustomer,
    ActiveAdsCount,
    AdsCount,
    NumberOfBanks,
    DepositCoveragePercentage,
    DepositDeviations,
    FixrateUniverseTest,

    // Depositor
    NorwayExposureMap,
    DepositInterestByProductForDepositor,
    DepositInterestForDepositorWithSsb,
    DepositMarginByProductForDepositor,

    // Common
    Nibor,
    NiborAll,
    AllDepositsInterest,
    TurnoverByProduct,
    BusinessVolume,
    DepositDuration,
    DepthByInterestForNiborProducts,
    DepthByInterestForFixedProducts ,
    DepositInterestByBankSegment,
    DepositMarginByProduct,
    DepositMarginByBankSegment,
    AdInterestByProduct,
    AdInterestByBankSegment,
    AdInterestWithMajorBanks,
    AdMarginByProduct,
    AdMarginByBankSegment,
    DepthByBankSegment,
    DepthByProduct,
    DepositInterestByBankSegmentWithSsb,
    DepositInterestWithSsb,
    MeanDepthByBankSegment,
    DepositVolumeByBankSegment,
    MeanDepositVolumeByBankSegment,

    // Bank
    BusinessVolumeForBank,
    TurnoverByProductForBank,
    DepositInterestByProductForBank,
    DepositInterestForBank,
    DepositMarginByProductForBank,
    DepositMarginForBank,
    AdInterestForBank,
    AdMarginForBank,
    DepthForBank,
    DepositVolumeByBankAndBankSegment,
    NorwayCustomerLocationMap,
};