import { useEffect, useState } from "react";
import { closeWebsocket, openWebsocket } from "../../../../websocketConnection";
import { CypressTestStatus, DepositBuyOrderType, FundBuyOrderType, UserLogOutEvent } from "../types";
import { websocketMessages } from "../utils/websocketMessages";
import { WebsocketContext } from "./WebsocketContext";
import { OrderType } from "../FixrateCity";

function WebsocketProvider({ children, jwt } : { children: React.ReactNode, jwt: unknown }) {
    const [nightTest, setNightTest] = useState(null)
    const [smokeTest, setSmokeTest] = useState(null)
    const [order, setOrder] = useState<OrderType>()

    useEffect(() => {
        openWebsocket(jwt, (type, payload) => {
            switch (type) {
                case websocketMessages.NEW_ORDER: {
                    const load : DepositBuyOrderType = payload
                    setOrder(load)
                    break;
                }
                case websocketMessages.NEW_FUND_BUY_ORDER: {
                    const load : FundBuyOrderType = payload
                    const order = { ...load, volume: load.volume / 1_000_000 }
                    setOrder(order)
                    break;
                }
                case websocketMessages.USER_LOGGED_IN: {
                    console.log("USER_LOGGED_IN", payload)
                    //console.log(usersLoggedIn)
                    //addUser(payload);
                    break;
                }
                case websocketMessages.USER_LOGGED_OUT: {
                    console.log("USER_LOGGED_OUT", payload)
                    const load : UserLogOutEvent = payload
                    //removeUser(load)
                    break;
                }
                case websocketMessages.CYPRESS_SMOKE_TEST_RESULT: {
                    const failed = payload.buildResult === "failure"
                    const smokeTestStatus: CypressTestStatus = {
                        success: !failed,
                        message: payload.buildStatus,
                    }
                    setSmokeTest(smokeTestStatus)

                    break;
                }
                case websocketMessages.CYPRESS_NIGHTLY_TEST_RESULT: {
                    const failed = payload.buildResult === "failure"
                    const nightlyTestStatus: CypressTestStatus = {
                        success: !failed,
                        message: payload.buildStatus,
                    }

                    setNightTest(nightlyTestStatus)
                    break;
                }
            }
        })
        return () => {
            closeWebsocket();
        };
    }, [jwt]);

    return (
        <WebsocketContext.Provider 
            value={{
                jwt: jwt, 
                state: { 
                    nightTest: nightTest, 
                    smokeTest: smokeTest, 
                    order: order
                }
            }}>
            {children}
        </WebsocketContext.Provider>
    )
}

export { WebsocketProvider };
