import React from 'react'
import read from '../config'
import {closeWebsocket, openWebsocket} from '../websocketConnection'

export default function FixrateUniverseTest({jwt}) {

    const [data, setData] = React.useState(null)
    const [wsData, setWsData] = React.useState(null)

    function fetchData() {
        fetch(read().marketplaceBaseUrl + '/api/universe/test', {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },
        })
            .then(response => response.ok && response.json())
            .then(data => setData(data))
            .catch(error => console.error(error))
    }

    return (
        <div>
            <h1>Fixrate Universe</h1>
            <h2>HTTP</h2>
            <button onClick={fetchData}>Send request with JWT</button>
            <pre>{JSON.stringify(data, null, 2)}</pre>
            <h2>Websocket</h2>
            <pre>{JSON.stringify(wsData, null, 2)}</pre>
            <button onClick={() => openWebsocket(jwt, setWsData)}>Open</button>
            <button onClick={closeWebsocket}>Close</button>
        </div>
    )
}