import { useQueries } from '@tanstack/react-query'
import { GET } from '../../../fixrateCity/src/utils/networkCallApi'
import { EChartsOption } from '../echarts'
import EChart from '../EChart'
import { PURPLE, SIGNAL_YELLOW, SPRING_GREEN } from '../../../../colors/colors'
import { Box, Grid, Typography } from '@mui/material'
import { Period } from '../../KPIDashboard'

export const ActiveCustomersSeriesChart = ({ token, period }: { token: string, period: Period }) => {
    const results = useQueries({
        queries: [
            {
                queryKey: ['customers-series' + period, token],
                queryFn: () => GET('/api/stagg/kpi/active-customers-series?range=' + period, token),
            },
            {
                queryKey: ['new-customers', token],
                queryFn: () => GET('/api/stagg/kpi/new-customers', token),
            },
            {
                queryKey: ['active-customers', token],
                queryFn: () => GET('/api/stagg/kpi/active-customers', token),
            },
            {
                queryKey: ['customers', token],
                queryFn: () => GET('/api/stagg/kpi/customers', token),
            },
        ],
    })

    let activeCustomersSeries
    let newCustomers
    let activeCustomers
    let customersChurn

    let xAxisValues: string[]

    let customers: number[]
    let target: number[]

    let newCustomersPercentage: number = 0
    let activeCustomersPercentage: number = 0

    let newCustomersValue: number
    let newCustomersBudget: number
    let activeCustomersValue: number
    let activeCustomersBudget: number
    let activeCustomersChange: number
    let customersNew
    let customersLost
    let customersReactivated

    const periodMapping: { [key in Period]: string } = {
        '1W': 'WEEK',
        '1M': 'MONTH',
        YTD: 'YTD',
    }
    const periodString = periodMapping[period]

    if (!results[0].isPending && !results[1].isPending && !results[2].isPending && !results[3].isPending) {
        activeCustomersSeries = results[0]?.data
        newCustomers = results[1]?.data
        activeCustomers = results[2]?.data
        customersChurn = results[3]?.data

        const dataToMap = activeCustomersSeries[0].series
        const series = dataToMap.reduce(
            (result, data) => ({
                ...result,
                [data.name]: data.values,
            }),
            {}
        )

        xAxisValues = series.activeCustomers.map(value => value[0])
        customers = series.activeCustomers.map(value => value[1])
        target = series.budget.map(value => value[1].toFixed(0))
        customersNew = customersChurn.new[periodString]
        customersLost = customersChurn.lost[periodString]
        customersReactivated = customersChurn.reactivated[periodString]

        newCustomersValue = newCustomers[periodString].value
        newCustomersBudget = newCustomers[periodString].budget.toFixed(0)
        activeCustomersValue = activeCustomers['YESTERDAY'].value
        activeCustomersBudget = activeCustomers['YESTERDAY'].budget
        activeCustomersChange = activeCustomers[periodString].change


    }

    const CustomerSeriesOptions: EChartsOption = {
        tooltip: {
            trigger: 'axis',
        },
        legend: {
            data: ['Aktive kunder', 'Mål'],
            right: '5%',
            top: 0,
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '1%',
            containLabel: true,
        },
        toolbox: {
            feature: {
                saveAsImage: {},
            },
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: xAxisValues,
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                name: 'Aktive kunder',
                type: 'line',
                smooth: true,
                symbol: 'triangle',
                symbolSize: 6,
                showSymbol: true,
                data: customers,
                color: PURPLE[300],
                lineStyle: {
                    width: 3,
                    type: 'solid',
                    color: PURPLE[300],
                },
            },
            {
                name: 'Mål',
                type: 'line',
                smooth: true,
                symbol: 'circle',
                symbolSize: 6,
                showSymbol: true,
                data: target,
                color: SIGNAL_YELLOW[600],
                lineStyle: {
                    width: 3,
                    type: 'solid',
                    color: SIGNAL_YELLOW[600],
                },
            },
        ],
    }

    const trend = (value: number) => {
        if (value === 0)
            return (
                <Typography sx={{ color: SIGNAL_YELLOW[600] }} variant='h3' display={'inline-flex'}>
                    <i className='ri-arrow-right-line'></i>
                </Typography>
            )
        return (
            <Typography sx={{ color: SPRING_GREEN[500] }} variant='h3' display={'inline-flex'}>
                {value > 0 ?
                    <Typography sx={{ display: 'inline-flex', pl: '1rem', pr: '1rem', color: SPRING_GREEN[500], alignItems: 'center', height: '100%' }} variant='h5'>+{value}</Typography>
                    :
                    <Typography sx={{ display: 'flex', pl: '1rem', pr: '1rem', color: SPRING_GREEN[500], alignItems: 'center', height: '100%' }} variant='h5'>{value}</Typography>
                }
            </Typography>
        )
    }

    return (
        <Box style={{ height: '31vh' }}>
            <EChart loading={activeCustomersSeries?.isPending} options={CustomerSeriesOptions} error={activeCustomersSeries?.error} style={{ height: '24vh' }} />

            <Grid container spacing={2} paddingTop={'2rem'}>
                <Grid item xs={6}>
                    <Box sx={{ backgroundColor: PURPLE[700], p: '0.5rem 1rem', borderRadius: '1rem' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant='h3' fontWeight={600}>
                                    {customersNew?.value + customersReactivated?.value}
                            </Typography>
                                {customersNew?.change && customersReactivated?.change ? trend(customersNew?.change + customersReactivated?.change) : ''}
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width:'100%' }}>
                                <Typography variant='body1' sx={{ marginLeft: '1rem' }}>
                                    nye + reakt.
                                </Typography>
                                <Typography variant='body1'>
                                    ({customersNew?.value} + {customersReactivated?.value})
                                </Typography>
                            </Box>
                        </Box>
                        <Typography variant='h6' sx={{ color: SPRING_GREEN[500] }}>
                            Nye aktive kunder
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box sx={{ backgroundColor: PURPLE[700], p: '0.5rem 1rem', borderRadius: '1rem' }}>
                        <Typography variant='h3' fontWeight={600}>
                            {customersLost?.value}
                            {customersLost?.change ? trend(customersLost?.change) : ''}
                        </Typography>
                        <Typography variant='h6' sx={{ color: SPRING_GREEN[500] }}>
                            Tapte kunder
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box sx={{ backgroundColor: PURPLE[700], p: '0.5rem 1rem', borderRadius: '1rem' }}>
                        <Typography variant='h3' fontWeight={600}>
                            {activeCustomersValue}
                            {trend(activeCustomersChange)}
                        </Typography>
                        <Typography variant='h6' sx={{ color: SPRING_GREEN[500] }}>
                            Aktive kunder
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
