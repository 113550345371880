import Matter from "matter-js";
import { forwardRef, useImperativeHandle, useRef } from "react";
import styles from "../../app.module.scss";
import moneyBagImage from "../../images/money-bags/money-bag.png";
import bigMoneyBagImage from "../../images/money-bags/big-money-bag.png";

const Vault = forwardRef(({ initialVolume } : { initialVolume: number}, ref) => {
    const Engine = Matter.Engine;
    const Render = Matter.Render;
    const Runner = Matter.Runner;
    const Composite = Matter.Composite;
    const Common = Matter.Common;
    const MouseConstraint = Matter.MouseConstraint;
    const Mouse = Matter.Mouse;
    const Bodies = Matter.Bodies;
    const engine = useRef(null);
    const runner = useRef(null);
    const render = useRef(null);
    const world = useRef(null);
    const moneyBagPaths = document.querySelectorAll("#moneybag > path")
    const bigMoneyBagPaths = document.querySelectorAll("#big-money-bag > path")

    useImperativeHandle(ref, () => ({
        addMoneyBag,
        resetVault,
        createWorld,
        addBigMoneyBag
    }));

    const moneyBagSvg = function () {
        var arr = [];
        moneyBagPaths.forEach((path) => {
            if (path) {
                arr.push(
                    Matter.Vertices.scale(
                        Matter.Svg.pathToVertices(path, 5),
                        0.5,
                        0.5
                    )
                );
            }
        });
        const body = Bodies.fromVertices(
            Common.random(100, 580),
            Common.random(100, 580),
            arr,
            {
                render: {
                    fillStyle: "#EBC568",
                    sprite: {
                        texture: moneyBagImage,
                        xScale: 0.5,
                        yScale: 0.5,
                    },
                },
            }
        );

        return body;
    }

    const bigMoneyBagSvg = function () {
        var arr = []
        bigMoneyBagPaths.forEach((path) => {
            if (path) {
                arr.push(
                    Matter.Vertices.scale(
                        Matter.Svg.pathToVertices(path, 5),
                        0.5,
                        0.5
                    )
                );
            }
        });
        const body = Bodies.fromVertices(
            Common.random(100, 580),
            Common.random(100, 580),
            arr,
            {
                render: {
                    fillStyle: "#EBC568",
                    sprite: {
                        texture: bigMoneyBagImage,
                        xScale: 0.5,
                        yScale: 0.5,
                    },
                },
            }
        );

        return body;
    }

    const createWorld = () => {
        if (world.current) {
            return
        }

        // create engine
        engine.current = Engine.create();
        world.current = engine.current.world;

        // create renderer
        render.current = Render.create({
            element: document.getElementById("vault"),
            engine: engine.current,
            options: {
                width: 800,
                height: 600,
                showAngleIndicator: false,
                wireframes: false,
                background: "transparent",
            },
        });

        Render.run(render.current);

        // create runner
        runner.current = Runner.create();
        Runner.run(runner.current, engine.current);

        // add frame
        Composite.add(world.current, [
            Bodies.rectangle(400, 5, 800, 20, {
                isStatic: true,
                render: { fillStyle: "gray" },
            }),
            Bodies.rectangle(0, 300, 20, 600, {
                isStatic: true,
                render: { fillStyle: "gray" },
            }),
            Bodies.rectangle(800, 300, 20, 600, {
                isStatic: true,
                render: { fillStyle: "gray" },
            }),
            Bodies.rectangle(400, 600, 1200, 20, {
                isStatic: true,
                render: { fillStyle: "gray" },
            }),
        ]);

        const moneyBags = []

        const bigBags = Math.floor(initialVolume / 100)
        const smallBags = initialVolume - (bigBags * 100)

        for (var bagIndex = 0; bagIndex < smallBags; bagIndex++) {
            moneyBags.push(moneyBagSvg());
        }

        for (var bigBagIndex = 0; bigBagIndex < bigBags; bigBagIndex++) {
            moneyBags.push(bigMoneyBagSvg());
        }

        Composite.add(world.current, moneyBags);

        // add mouse control
        var mouse = Mouse.create(render.current.canvas),
            mouseConstraint = MouseConstraint.create(engine.current, {
                mouse: mouse,
                constraint: {
                    stiffness: 0.2,
                    render: {
                        visible: false,
                    },
                },
            });

        Composite.add(world.current, mouseConstraint);

        // keep the mouse in sync with rendering
        render.current.mouse = mouse;

        // fit the render viewport to the scene
        Render.lookAt(render.current, {
            min: { x: 0, y: 0 },
            max: { x: 800, y: 600 },
        });

        // wrapping using matter-wrap plugin
        var allBodies = Composite.allBodies(world.current);

        for (var i = 0; i < allBodies.length; i += 1) {
            allBodies[i].plugin.wrap = {
                min: { x: render.current.bounds.min.x - 100, y: render.current.bounds.min.y },
                max: { x: render.current.bounds.max.x + 100, y: render.current.bounds.max.y },
            };
        }
    }

    const addMoneyBag = () => {
        Composite.add(world.current, moneyBagSvg());
    };

    const addBigMoneyBag = () => {
        Composite.add(world.current, bigMoneyBagSvg());
    };

    const resetVault = () => {
        Composite.remove(
            world.current,
            world.current.bodies.filter((body) => body.label !== "Rectangle Body")
        );
    }

    return <div id="vault" className={styles.vault}></div>
});

export default Vault