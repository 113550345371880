import { createContext } from 'react';
import { OrderType } from '../FixrateCity';
import { CypressTestStatus } from '../types';

type WebsocketContextType = {
    jwt: unknown,
    state: {
        nightTest: CypressTestStatus,
        smokeTest: CypressTestStatus,
        order: OrderType,
    }
}

const WebsocketContext = createContext<WebsocketContextType>({
    jwt: "",
    state: {
        nightTest: null,
        smokeTest: null,
        order: null,
    }
});

export { WebsocketContext };
