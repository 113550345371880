import ReconnectingWebSocket from 'reconnecting-websocket'
import read from './config'

const WEBSOCKET_BASE_URL = read().websocketBaseUrl

export const websocket = {
    ws: undefined,
    url: WEBSOCKET_BASE_URL + '/api/universe/ws',
    queuedMessages: [],
}

export function openWebsocket(jwt, handleWebsocketMessage) {
    if (!websocket.ws || websocket.ws.readyState !== websocket.ws.OPEN) {

        console.log('Opening websocket to ' + websocket.url)
        let connectionStartTime = new Date().getTime()

        websocket.ws = new ReconnectingWebSocket(websocket.url + '?jwt=' + jwt)

        websocket.ws.addEventListener('open', () => {
            if (connectionStartTime > 0) {
                const startupTime = new Date().getTime() - connectionStartTime
                console.log('onopen of', websocket.url, 'in', startupTime, 'ms')
                if (startupTime > 2500) {
                    connectionStartTime = 0
                }
            }
            sendQueuedMessages(websocket)
        })

        websocket.ws.addEventListener('message', (msg) => {
            const message = JSON.parse(msg.data)
            console.log('Websocket message from ' + websocket.url, Object.assign({}, message))
            if (message.hasOwnProperty('type') && message.hasOwnProperty('payload')) {
                handleWebsocketMessage(message.type, message.payload)
            }
        })

        websocket.ws.addEventListener('error', (err) => {
            console.error('Websocket error (' + websocket.url + ')', err)
        })

        websocket.ws.addEventListener('close', (event) => {
            console.log('Websocket close (' + websocket.url + ')', event)
            if (event.reason === 'Session is not authenticated') {
                console.log('Websocket session is not authenticated (' + websocket.url + '). Will now stop reconnecting.')
                websocket.ws.close()
            }
        })
    }
}

export function closeWebsocket() {
    if (websocket.ws) {
        console.log('Closing websocket ' + websocket.url, websocket.ws)
        websocket.ws.close(1000, '')
    }
}

export function sendMessage(message) {
    if (websocket.ws) {
        console.log('Sending websocket message to ' + websocket.url, message)
        websocket.ws.send(JSON.stringify(message))
    } else {
        // Queue up this message, if the websocket is not open yet
        websocket.queuedMessages.push(message)
    }
}

function sendQueuedMessages() {
    if (websocket.queuedMessages) {
        websocket.queuedMessages.forEach(message => {
            console.log('Sending queued websocket message to ' + websocket.url, message)
            websocket.ws.send(JSON.stringify(message))
        })
        delete websocket.queuedMessages
    }
}

