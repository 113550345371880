const read = () => {

    let marketplaceUrl = "https://market.fixrate.no";

    if (window.location.hostname === 'dashboard.stage.fixrate.org') {
        document.title = 'Fixrate Dashboard : stage'
        marketplaceUrl = 'https://market.stage.fixrate.org'
    }

    if (window.location.hostname === 'dashboard.test.fixrate.org') {
        document.title = 'Fixrate Dashboard : test'
        marketplaceUrl = 'https://market.test.fixrate.org'
    }

    if (window.location.hostname === 'dashboard.test.fixrate.no') {
        document.title = 'Fixrate Dashboard : test'
        marketplaceUrl = 'https://market.test.fixrate.no'
    }

    if (window.location.hostname === 'dashboard.demo.fixrate.no') {
        document.title = 'Fixrate Dashboard : demo'
        marketplaceUrl = 'https://demo.fixrate.no'
    }

    if (window.location.hostname === 'dashboard') {
        document.title = 'Fixrate Dashboard : dev'
        marketplaceUrl = 'http://dashboard'
    }

    if (window.location.hostname === 'dashboard.fixrate.dev') {
        document.title = 'Fixrate Dashboard : dev'
        marketplaceUrl = 'https://market.fixrate.dev'
    }

    if (['localhost', '127.0.0.1', ''].includes(window.location.hostname) ){
        document.title = 'Fixrate Dashboard : dev'
        marketplaceUrl = 'http://localhost:3000'
    }

    return {
        marketplaceBaseUrl: marketplaceUrl,
        websocketBaseUrl: (window.location.protocol === 'https:' ? 'wss://' : 'ws://') + window.location.host,
    }
}

export default read
