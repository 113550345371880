import '@fixrate/fixrate-analytics-widgets/dist/index.css'
import { ThemeProvider } from '@mui/material'
import { useParams } from 'react-router'
import 'remixicon/fonts/remixicon.css'
import { DASHBOARDS } from '../dashboards'
import { useToken } from '../hooks/useToken'
import { isAuthorized } from '../security'
import FixrateCity from './fixrateCity/src/FixrateCity'
import { WebsocketProvider } from './fixrateCity/src/providers/WebsocketProvider'
import theme from './theme'

const App = ({fixrateCity} : {fixrateCity?: boolean}) => {
    const { token, decodedJwt, error, requestId, requestIdSeconds } = useToken()
    const forParam = useParams().for
    const dashboardParam = useParams().dashboard
    const dashboard = DASHBOARDS.find(dashboard => dashboard.name === (fixrateCity ? "fixrate" : dashboardParam))
    
    // Skip checking for dashboard if it is Fixrate City
    if (!fixrateCity) {
        if (!dashboardParam) return <div className="page"><p>No dashboard selected.</p></div>
        if (!dashboard) return <div className="page"><p>Dashboard not found.</p></div>
    }

    if (requestId) {
        if (requestIdSeconds > 0) {
            return <div className="page"><p>Authentication passphrase <b>'{requestId}'</b> is valid for another {Math.floor(requestIdSeconds / 60)} minutes and {requestIdSeconds % 60} seconds.</p></div>
        } else {
            return <div className="page"><p>Authentication passphrase <b>'{requestId}'</b> has timed out. Refresh page to try again. </p></div>
        }
    }

    if (error) return <div className="page"><p>Authentication error.</p></div>

    if (!decodedJwt) return null

    if (!isAuthorized(dashboard, decodedJwt, forParam)) {
        return <div className="page"><p>Not authorized for dashboard.</p></div>
    }

    return (
        <ThemeProvider theme={theme}>
            <WebsocketProvider jwt={token}>
                <FixrateCity jwt={token} decodedJwt={decodedJwt} dashboard={dashboard}/>
            </WebsocketProvider>
        </ThemeProvider>
    )
}

export default App