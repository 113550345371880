import { setJwtForStagg } from '@fixrate/fixrate-analytics-widgets'
import '@fixrate/fixrate-analytics-widgets/dist/index.css'
import { useEffect, useState } from 'react'
import 'remixicon/fonts/remixicon.css'
import { decodeJwt, generateJwt, isSessionAuthenticated } from '../security'
import { sentence } from '../sentence'

export const useToken = () => {
    const [token, setToken] = useState<string>('')
    const [decodedJwt, setDecodedJwt] = useState<unknown>(null)
    const [error, setError] = useState<Error>(null)
    const [requestId, setRequestId] = useState<string>(null)
    const [requestIdSeconds, setRequestIdSeconds] = useState<number>(0)

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (requestIdSeconds > 0) setRequestIdSeconds(s => s - 1)
        }, 1000)
        return (() => clearInterval(intervalId))
    }, [requestIdSeconds])

    useEffect(() => {
        init()
    }, [])

    async function init() {
        try {
            const sessionAuthenticated = await isSessionAuthenticated()

            let passphrase = null
            if (!sessionAuthenticated) {
                passphrase = sentence()
                console.log(`Using passphrase '${passphrase}' as there is no authenticated session`)
                setRequestId(passphrase)
                setRequestIdSeconds(300)
            }

            const jwtToken = await generateJwt(!sessionAuthenticated && passphrase)
            setRequestIdSeconds(0)
            setRequestId(null)
            setToken(jwtToken)
            setJwtForStagg(jwtToken)
            const decoded = decodeJwt(jwtToken)
            console.log('Decoded JWT: ' + JSON.stringify(decoded))
            setDecodedJwt(decoded)
        } catch (e) {
            console.log('Error during authentication: ' + e)
            setError(e)
        }
    }

    return { token, decodedJwt, error, requestId, requestIdSeconds }
}
