export const DASHBOARDS = [
    {
        name: 'fixrate',
        authorization: 'fixrate',
        interval: 18000,
        transitionTime: 1000,
        widgets: [
            {name: 'ActiveOrders'},
            {name: 'Volumes'},
            {name: 'IncomeNow'},
            // {name: 'ActiveUsers'},
            {name: 'NorwayDepositorVolumeMap'},
            {name: 'NorwayBankVolumeMap'},
            {name: 'Nibor'},
            {name: 'TurnoverByProduct'},
            {name: 'DepositInterestWithSsb'}
        ]
    },
    {
        name: 'bank',
        authorization: 'bank',
        interval: 12000,
        transitionTime: 1000,
        widgets: [
            {name: 'BusinessVolumeForBank'},
            {name: 'TurnoverByProductForBank'},
            {name: 'DepositInterestByProductForBank'},
            {name: 'DepositInterestForBank'},
            {name: 'DepositMarginByProductForBank'},
            {name: 'DepositMarginForBank'},
            {name: 'AdInterestForBank'},
            {name: 'AdMarginForBank'},
            {name: 'DepthForBank'},
            {name: 'DepositVolumeByBankAndBankSegment'},
            {name: 'NorwayCustomerLocationMap'},
            {name: 'Nibor'},
            {name: 'NiborAll'}
        ]
    },
    {
        name: 'depositor',
        authorization: 'depositor',
        interval: 12000,
        transitionTime: 1000,
        widgets: [
            {name: 'Nibor'},
            {name: 'NiborAll'},
            {name: 'NorwayExposureMap'},
            {name: 'DepositInterestByProductForDepositor'},
            {name: 'DepositInterestForDepositorWithSsb'},
            {name: 'DepositMarginByProductForDepositor'}
        ]
    },
    {
        name: 'universe',
        authorization: 'fixrate',
        interval: 12000,
        transitionTime: 1000,
        widgets: [
            {name: 'FixrateUniverseTest'}
        ]
    }
]