import { useQueries } from '@tanstack/react-query'
import { GET } from '../../../fixrateCity/src/utils/networkCallApi'
import { EChartsOption } from '../echarts'
import EChart from '../EChart'
import { Box, Grid, Typography } from '@mui/material'
import { PURPLE, SIGNAL_YELLOW, SPRING_GREEN } from '../../../../colors/colors'
import { Period } from '../../KPIDashboard'
import CurrencyOutput from '../../../fixrateCity/src/components/CurrencyOutput/CurrencyOutput'

export const NetFlowSeriesChart = ({ token, period }: { token: string, period: Period }) => {
    const results = useQueries({
        queries: [
            {
                queryKey: ['netflow-series' + period, token],
                queryFn: () => GET('/api/stagg/kpi/net-flow-series?range=' + period, token),
            },
            {
                queryKey: ['netflow', token],
                queryFn: () => GET('/api/stagg/kpi/net-flow', token),
            },
        ],
    })

    let netflowSeries
    let netflow

    let xAxisValues: string[]

    let budget: number[]
    let bankIn: number[]
    let bankOut: number[]
    let fundIn: number[]
    let fundOut: number[]
    let bankVolumeNet: number[]
    let fundVolumeNet: number[]
    let bankVolumeNetTotal: number
    let fundVolumeNetTotal: number

    let netFlowValue: number
    let netFlowChange: number

    const periodMapping: { [key in Period]: string } = {
        '1W': 'WEEK',
        '1M': 'MONTH',
        YTD: 'YTD',
    }
    const periodString = periodMapping[period]

    if (!results[0].isPending && results[0].isSuccess && !results[1].isPending && results[1].isSuccess) {
        netflowSeries = results[0]
        netflow = results[1].data
        const dataToMap = results[0].data[0].series
        const series = dataToMap.reduce(
            (result, data) => ({
                ...result,
                [data.name]: data.values,
            }),
            {}
        )
        xAxisValues = series.bankVolumeIn.map(value => value[0])
        bankVolumeNet = series.bankVolumeIn.map((value, index) => {
            let inValue = parseFloat(value[1].toFixed(0))
            let outValue = series.bankVolumeOut.length > 0 ? parseFloat(series.bankVolumeOut[index][1].toFixed(0)):0
            return inValue - outValue
        })
        fundVolumeNet = series.fundVolumeIn.map((value, index) => {
            let inValue = parseFloat(value[1].toFixed(0))
            let outValue = parseFloat(series.fundVolumeOut[index][1].toFixed(0))
            return inValue - outValue
        })
        bankVolumeNetTotal = bankVolumeNet.reduce((a, b) => a + b, 0)
        fundVolumeNetTotal = fundVolumeNet.reduce((a, b) => a + b, 0)

        budget = series.budget.map(value => value[1].toFixed(0))

        netFlowValue = netflow[periodString].value.toFixed(0) * 1000000
        netFlowChange = netflow[periodString].change ? netflow[periodString].change.toFixed(0) * 1000000 : 0
    }

    const netFlowSeriesOptions: EChartsOption = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
        },
        toolbox: {
            feature: {
                saveAsImage: { show: true },
            },
        },
        legend: {
            data: ['Bank netto', 'Fond netto', 'Budsjett (totalt)'],
            icon: 'circle',
            right: '5%',
            top: '0',
        },
        xAxis: {
            data: xAxisValues,
            name: '',
            axisLine: { onZero: true },
            splitLine: { show: false },
            splitArea: { show: false },
        },
        yAxis: {},
        grid: {
            bottom: '20rem',
            left: '40rem',
            right: '10rem',
        },
        series: [
            {
                name: 'Bank netto',
                type: 'bar',
                stack: 'bankNet',
                data: bankVolumeNet,
                color: PURPLE[300],
            },
            {
                name: 'Fond netto',
                type: 'bar',
                stack: 'fundNet',
                data: fundVolumeNet,
                color: SPRING_GREEN[500],
            },
            {
                name: 'Budsjett (totalt)',
                type: 'line',
                smooth: true,
                lineStyle: {
                    width: 2,
                    color: SIGNAL_YELLOW[600],
                },
                showSymbol: true,
                data: budget,
                color: SIGNAL_YELLOW[600],
            },
        ],
    }

    const incomeTrend = (value: number) => {
        if (value === 0) return ''
        return value > 0 ? `+ ${CurrencyOutput.formatMillionWithDecimals(value)}` : `${CurrencyOutput.formatMillionWithDecimals(value)}`
    }

    return (
        <Box style={{ height: '37vh' }}>
            <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item xs={8}>
                    <Typography variant='h2' fontWeight={800} display={'inline-flex'}>
                        {CurrencyOutput.formatMillionWithDecimals(netFlowValue)}
                    </Typography>
                    <Typography sx={{ display: 'inline-flex', pl: '1rem', color: SPRING_GREEN[400], fontWeight: 600 }} variant='h5'>
                        {incomeTrend(netFlowChange)}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={6}>
                            <Box sx={{ backgroundColor: PURPLE[700], p: '0.5rem 1rem', borderRadius: '1rem' }}>
                                <Typography variant='h5' fontWeight={600}>
                                    Bank: {CurrencyOutput.formatMillionWithDecimals(bankVolumeNetTotal*1000000)}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{ backgroundColor: PURPLE[700], p: '0.5rem 1rem', borderRadius: '1rem' }}>
                                <Typography variant='h5' fontWeight={600}>
                                    Fond: {CurrencyOutput.formatMillionWithDecimals(fundVolumeNetTotal*1000000)}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <EChart loading={netflowSeries?.isPending} options={netFlowSeriesOptions} error={netflowSeries?.error} style={{ height: '31vh' }} />
        </Box>
    )
}
