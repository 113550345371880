// Original source file in fixrate-universe. Do not edit from other projects.
// Copy the contents of this file to fixrate-dashboard://src/.

export const websocketMessages = {
    NEW_ORDER: 'NEW_ORDER',
    NEW_FUND_BUY_ORDER: 'NEW_FUND_BUY_ORDER',
    USER_LOGGED_IN: 'USER_LOGGED_IN',
    USER_LOGGED_OUT: 'USER_LOGGED_OUT',
    CYPRESS_NIGHTLY_TEST_RESULT: 'CYPRESS_NIGHTLY_TEST_RESULT',
    CYPRESS_SMOKE_TEST_RESULT: 'CYPRESS_SMOKE_TEST_RESULT',
}
