import read from '../../../../config'

function handleNetworkErrors(ex) {
    if (ex) {
      console.error('Network error', ex);
    }
  
    return Promise.reject();
}

function _catch(body, recover) {
    try {
        var result = body();
    } catch(e) {
        return recover(e);
    }
    if (result && result.then) {
        return result.then(void 0, recover);
    }
    return result;
}

var parseJson = function parseJson(response) {
    try {
      return Promise.resolve(_catch(function () {
        return Promise.resolve(response.json());
      }, function (err) {
        console.error('Failed to parse response as json', err);
        return Promise.reject();
      }));
    } catch (e) {
      return Promise.reject(e);
    }
  };

function handleStatusCodeErrors(response, customErrorMessages) {
    var clone = response.clone();
  
    if (!response.ok) {
      if (customErrorMessages && customErrorMessages[response.status]) {
        response.text().then(function (text) {
          console.error('Network response', response.statusText, customErrorMessages[response.status].message);
        });
      } else if (response.status === 403) {
        response.text().then(function (text) {
          var message = 'Ta kontakt med en Fixrate-administrator for å løse dette.';
          console.error('Network response', response.statusText, message);
        });
      } else if (response.status === 413) {
        response.text().then(function (text) {
          var message = 'Dokumentet er for stort til at systemet kan håndtere det.';
          console.error('Network response', response.statusText, message);
        });
      } else {
        response.text().then(function (text) {
          var message = text && text.length < 300 ? text : 'Du kan prøve igjen, eller ta kontakt med support.';
          console.error('Network response', response.statusText, message);
        });
      }
  
      return Promise.reject();
    }
  
    return clone;
}

export const GET = (path, jwt) => {
    var options = {
        headers: {
            'Accept': 'application/json'
        },
        credentials: 'include'
    };

    let baseUrl = ''

    if (jwt) {
        options.headers.Authorization = 'Bearer ' + jwt;
    }

    if (['localhost', '127.0.0.1', ''].includes(window.location.hostname)) {
      baseUrl = read().marketplaceBaseUrl
    }

    return fetch(baseUrl + path, options).then(function (response) {
        return handleStatusCodeErrors(response);
    }).then(parseJson)["catch"](function (ex) {
        return handleNetworkErrors(ex);
    });
}