// TODO fixme make this truly i18-ified

function createValutaFormatter(withCurrency = true, minimumDecimals = 2, maximumDecimals = 2) {
    return new Intl.NumberFormat('nb-NO', {
        style: withCurrency ? 'currency' : undefined,
        currency: withCurrency ? 'NOK' : undefined,
        minimumFractionDigits: minimumDecimals,
        maximumFractionDigits: maximumDecimals,
    })
}

type CurrencyOutputOptions = {
    withCurrency?: boolean,
    minimumDecimals?: number,
    maximumDecimals?: number,
    millionFormat?: boolean
}

export const CurrencyOutputObject = (value: number, options?: CurrencyOutputOptions) => {
    const defaultOptions : CurrencyOutputOptions = {
        withCurrency: true,
        minimumDecimals: 0,
        maximumDecimals: 0,
        millionFormat: false
    }

    const mergedOptions = { ...defaultOptions, ...options }
    const cappedValue = value < 0 ? Math.ceil(value / 1000000) : Math.floor(value / 1000000)
    const valutaFormatter = createValutaFormatter(mergedOptions.withCurrency, mergedOptions.minimumDecimals, mergedOptions.maximumDecimals)
    return valutaFormatter.format(mergedOptions.millionFormat ? cappedValue : value)
}

const CurrencyOutput = () => {
}
CurrencyOutput.formatDecimal = (value) => {
    const valutaFormatter = createValutaFormatter(false, 0, 20)
    if (value !== 0 && !value) return null
    if (document.cookie.includes('_fixrate_stop_time')) {
        return valutaFormatter.format(0)
    } else {
        return valutaFormatter.format(value)
    }
}

CurrencyOutput.formatNoCode = (value, decimals = 2) => {
    const valutaFormatter = createValutaFormatter(false, decimals, decimals)
    if (value !== 0 && !value) return null
    if (document.cookie.includes('_fixrate_stop_time')) {
        return valutaFormatter.format(0)
    } else {
        return valutaFormatter.format(value)
    }
}

CurrencyOutput.format = (value, decimals = 2) => {
    const valutaFormatter = createValutaFormatter(true, decimals,decimals)
    if (value === 0) return valutaFormatter.format(0)
    if (!value) return null
    if (document.cookie.includes('_fixrate_stop_time')) {
        return valutaFormatter.format(0)
    } else {
        return valutaFormatter.format(value)
    }
}

// withInterest can be set to false to make headless tests display the value
CurrencyOutput.formatNoDecimal = (value, withInterest = true) => {
    const valutaFormatter = createValutaFormatter(true, 0,0)
    if (value === 0) return valutaFormatter.format(0)
    if (!value) return null
    if (document.cookie.includes('_fixrate_stop_time') && withInterest) {
        return valutaFormatter.format(0)
    } else {
        return valutaFormatter.format(Math.round(value))
    }
}

CurrencyOutput.formatMillion = (value, currency = undefined) => {
    const cappedValue = value < 0 ? Math.ceil(value / 1000000) : Math.floor(value / 1000000)
    const wholeMillion = cappedValue.toFixed(0)
    const formattedValue = wholeMillion.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    return formattedValue + ' M ' + (currency ?? '')
}

// formatter for millions, but I also want to return values below 1 million, like 0.2 M
CurrencyOutput.formatMillionWithDecimals = (value, currency = undefined) => {
    const cappedValue = value / 1000000
    const wholeMillion = cappedValue.toFixed(1)
    const formattedValue = wholeMillion.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    return formattedValue + ' M ' + (currency ?? '')
}

CurrencyOutput.formatBillion = (value, currency = undefined) => {
    const cappedValue = value / 1000000000
    const wholeBillion = cappedValue.toFixed(0)
    const formattedValue = wholeBillion.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    return formattedValue + ' MRD ' + (currency ?? '')
}

export default CurrencyOutput