import styles from './Communication.module.scss'
import Sigurd from './images/Sigurd.png'
import Brynjar from './images/Brynjar.png'
import DrEvil from './images/DrEvil.png'
import call from './sounds/codeccall.wav'
import hudOpen from './sounds/codecopen.wav'
import hudClose from './sounds/codecover.wav'
import {useEffect} from "react";

function Communication({name, title, message, actions, frequency = '140.85', close, silent = false}) {
    const callSound = new Audio(call)
    const hudOpenSound = new Audio(hudOpen)
    const hudCloseSound = new Audio(hudClose)

    useEffect(()=>{
        const callSoundTimeout = setTimeout(() => {
            if (!silent) {
                callSound.play()
            }
        }, 500)

        const hudOpenSoundTimeout = setTimeout(() => {
            if (!silent) {
                hudOpenSound.play()
            }
        }, 2500)

        return ()=>{
            clearTimeout(hudOpenSoundTimeout)
            clearTimeout(callSoundTimeout)
        }

    },[])

    const closeMessage = () => {
        if (!silent) {
            hudCloseSound.play()
        }
        close()
    }

    return (
        <>
            <svg height="0" width="0">
                <defs>
                    <clipPath id="svgPath">
                        <path d="M234 11.5C104.541 10.3 46.3936 174 40.1879 244H0V0H234V11.5Z" fill="#AAAAAA"/>
                    </clipPath>
                    <clipPath id="bars">
                        <rect y="224" width="235" height="20" fill="#567969"/>
                        <rect y="175" width="235" height="19" fill="#567969"/>
                        <rect y="125" width="235" height="19" fill="#567969"/>
                        <rect y="75" width="235" height="19" fill="#567969"/>
                        <rect y="25" width="235" height="19" fill="#567969"/>
                        <rect y="200" width="235" height="19" fill="#567969"/>
                        <rect y="150" width="235" height="19" fill="#567969"/>
                        <rect y="100" width="235" height="19" fill="#567969"/>
                        <rect y="50" width="235" height="19" fill="#567969"/>
                        <rect width="235" height="20" fill="#567969"/>
                    </clipPath>
                </defs>
            </svg>
            <div className={styles.communication}>
                <div className={styles.wrapper}>
                    <h2>Innkommende melding</h2>
                    <div className={styles.messageRow}>
                        <div className={styles.profile}>
                            {name === 'Brynjar Ellingsen' && <img src={Brynjar} alt="Brynjar"/>}
                            {name === 'Sigurd Stendal' && <img src={Sigurd} alt="Sigurd"/>}
                            {name === 'Dr. Evil' && <img src={DrEvil} alt="Dr. Evil"/>}
                            <p>{name}, {title}</p>
                        </div>
                        <div className={styles.message}>
                            <div className={styles.radioWrapper}>
                                <div className={styles.radio} style={{clipPath: 'url(#svgPath)'}}>
                                    <div className={styles.bars} style={{clipPath: 'url(#bars)'}}>
                                        <div className={styles.activeIndication}></div>
                                    </div>
                                </div>
                                <p>{frequency}</p>
                            </div>
                            <p className={styles.radioMessage}>
                                <span>{message}</span>
                            </p>
                        </div>
                    </div>
                    <div className={styles.buttonRow}>
                        {actions?.map((action) => <button onClick={() => window.open(action.url)}>{action.title}</button>)}
                        <button onClick={() => closeMessage()}>Lukk melding </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Communication
