import { Box, Button, Stack, Typography } from '@mui/material'
import { FOREST_GREEN } from '../../../../../colors/colors'
import { AdDto, BankDto, DepositDto } from '../../types'
import { AdCard } from '../AdCard/AdCard'
import { CurrencyOutputObject } from '../CurrencyOutput/CurrencyOutput'
import styles from './BankView.module.scss'
import BlackBoard from './assets/blackboard.svg'
import Desk from './assets/desk.svg'
import Floor from './assets/floor.svg'

export default function BankView({
    bank,
    deposits,
    ads,
    onClose,
}: {
    bank: BankDto
    deposits: DepositDto[]
    ads: AdDto[]
    onClose: () => void
}) {
    const uniqueDepositorIds = new Set()

    // Iterate through the array of deposits and add each unique depositorId to the Set
    for (const deposit of deposits) {
        uniqueDepositorIds.add(deposit.depositorId)
    }

    const numberOfUniqueDepositorIds = uniqueDepositorIds.size

    return (
        <Stack
            justifyContent={'flex-end'}
            sx={{ backgroundColor: '#EBDCA8' }}
            position={'fixed'}
            height='100%'
            width='100%'
            zIndex={'10'}
            top={'0'}
        >
            <Box position={'absolute'} top={'4rem'} right={'4rem'} color={FOREST_GREEN[500]}>
                <Button
                    color='inherit'
                    onClick={onClose}
                    variant='outlined'
                    startIcon={<i className='ri-close-line' />}
                    sx={{
                        minWidth: 'auto',
                        color: '#333',
                        fontFamily: 'Montserrat',
                        fontSize: '1.8rem',
                        fontWeight: '600',
                    }}
                >
                    Gå ut
                </Button>
            </Box>
            <Stack
                mb={'-8rem'}
                width={'120rem'}
                ml={'auto'}
                mr={'auto'}
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <Box
                    className={styles.blackBoard}
                    p={'6rem'}
                    width={'470px'}
                    height={'310px'}
                    sx={{
                        backgroundImage: 'url(' + BlackBoard + ')',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                    }}
                >
                    <Stack spacing={'2rem'}>
                        <Box>
                            <h3>Innskudd</h3>
                            <p>
                                {CurrencyOutputObject(
                                    deposits.reduce((acc, deposit) => acc + deposit.volume, 0),
                                    {
                                        withCurrency: false,
                                        minimumDecimals: 0,
                                        maximumDecimals: 0,
                                    }
                                )}{' '}
                                millioner kr
                            </p>
                        </Box>
                        <Box>
                            <h3>Antall kunder</h3>
                            <p>{numberOfUniqueDepositorIds}</p>
                        </Box>
                    </Stack>
                </Box>
                <Box>{ads.length > 0 ? <AdCard ad={ads[0]} bank={bank} /> : <p>Ingen tilbud på markedsplassen</p>}</Box>
            </Stack>
            <Stack alignItems={'center'}>
                <Box display={'flex'} position={'relative'} justifyContent={'center'} alignItems={'flex-end'}>
                    <Box position={'absolute'} mb={'10rem'}>
                        <Typography fontSize={'3rem'} fontFamily={'Montserrat'} fontWeight={'700'} color='#fedc44'>
                            {bank.name}
                        </Typography>
                    </Box>
                    <img src={Desk} width={'800px'} />
                </Box>
            </Stack>
            <Box height={'10rem'} sx={{ backgroundImage: 'url(' + Floor + ')', backgroundRepeat: 'repeat-x' }}></Box>
        </Stack>
    )
}
