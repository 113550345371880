import cx from 'classnames'
import * as echarts from 'echarts'
import React, { useEffect, useRef, useState } from 'react'
import theme from './theme.json'

interface EChartProps {
    loading: boolean
    options: any
    className?: string
    style?: object
    message?: any
    error?: Error

}

function EChart({ options, style, className, loading, message, error }: EChartProps) {
    const [chart, setChart] = useState(null)
    const chartRef = useRef()

    useEffect(() => {
        echarts.registerTheme('fixrate', theme)
        const chart = echarts.init(chartRef.current, 'fixrate')
        chart.setOption({ ...options, resizeObserver }, true)
        setChart(chart)
        if (resizeObserver) resizeObserver.observe(chartRef.current)
    }, [options])

    useEffect(() => {
        if (!chart) {
            return
        }
        if (loading) {
            chart.showLoading()
            return
        }

        chart.hideLoading()
    }, [chart, loading])

    useEffect(() => {
        if (chart && options && message) {
            chart.clear()
        }
    }, [chart, message, options])

    const newStyle = {
        height: 550,
        ...style,
    }

    if (error) return <p>Error: {error.message}</p>

    return (
        <div className='echarts-parent position-relative'>
            <div ref={chartRef} style={newStyle} className={cx('echarts-react', className)} />
            {message ? <div className='no-data'>{message}</div> : null}
        </div>
    )
}

const resizeObserver = new window.ResizeObserver((entries) => {
    entries.map(({ target }) => {
        const instance = echarts.getInstanceByDom(target as HTMLElement)
        if (instance) {
            instance.resize()
        }
    })
})

export default React.memo(EChart)
