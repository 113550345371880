import { Alert, Box, ButtonBase, Divider, Stack, SxProps, Typography } from '@mui/material'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { PURPLE, SUNSET_ORANGE } from '../../../../../colors/colors'
import { AdDto, BankDto } from '../../types'
import InterestOutput from '../InterestOutput/InterestOutput'
import styles from './AdCard.module.scss'

export type AdProps = {
    id: string
    bankName: string
    productId: string
    productName: '31 d' | '90 d'
    interest: number
    interestWithoutNibor: number
    currentNibor: number
    max: number
    min: number
    volume: number
    remaining: number
    adTag: 'NO_TAG' | 'NEW' | 'POPULAR' | 'ALMOST_FULL' | 'SPECIAL'
}

const AD_TYPES = {
    NO_TAG: { message: () => '', messageType: 0 },
    NEW: { message: () => 'Ny annonse', messageType: 1 },
    POPULAR: { message: () => 'Populær', messageType: 2 },
    ALMOST_FULL: { message: () => 'Snart utsolgt', messageType: 3 },
    SPECIAL: { message: () => 'Spesialtilbud', messageType: 4 },
}

type ProductNamesType = {
    [key: string]: string
}

const ProductNames: ProductNamesType = {
    productLongName1: '3 måneder',
    productLongName2: '6 måneder',
    productLongName3: '12 måneder',
    productLongName4: '24 måneder',
    productLongName5: '31 dager',
    productLongName6: '60 dager',
    productLongName7: '90 dager',
    productLongName8: 'FRN 24',
    productLongName9: 'FRN 36',
    productLongName10: '31 dager USD',
    productLongName11: 'Fastrente',
    productLongName12: '3 måneder ⟳',
}

function getIcon(messageType: number) {
    switch (messageType) {
        case 4:
            return 'ri-send-plane-2-line'
        case 3:
            return 'ri-time-line'
        case 2:
            return 'ri-star-line'
        case 1:
            return 'ri-flashlight-line'
        default:
            return null
    }
}

const fixedProducts = [1, 2, 3, 4, 11, 12]
const regularProducts = [5, 6, 7]
const frnProducts = [8, 9]

function getProductType(productId: string) {
    if (fixedProducts.includes(parseInt(productId))) {
        return 'FIXED'
    } else if (frnProducts.includes(parseInt(productId))) {
        return 'FRN'
    }

    return 'NIBOR3M'
}

export function AdCard({ ad, bank, sx, onClick }: { ad: AdDto; bank: BankDto; sx?: SxProps; onClick?: () => void }) {
    const [messageType, setMessageType] = useState(0)
    const [message, setMessage] = useState('')

    const isDisabled = ad.remainingVolume < ad.min

    function getProductName(productId: number) {
        return (
            <div className={styles.constraints}>
                <span className={styles.contstraints__title}>
                    {regularProducts.includes(productId) && 'Oppsigelsestid'}
                    {fixedProducts.includes(productId) && 'Løpetid'}
                    {frnProducts.includes(productId) && 'Produkt'}
                </span>
                <span className={styles.constraint__value}>
                    <span>{ProductNames['productLongName' + productId]}</span>
                </span>
            </div>
        )
    }

    useEffect(() => {
        setMessage(AD_TYPES[ad.tag || 'NO_TAG'].message())
        setMessageType(AD_TYPES[ad.tag || 'NO_TAG'].messageType)
    }, [ad])

    return (
        <ButtonBase
            disabled={isDisabled}
            onClick={onClick}
            color='primary'
            sx={{ textAlign: 'left', boxShadow: '0.2rem 0.2rem 0.2rem rgba(0,0,0,0.15)', ...sx }}
        >
            <Box className={classNames(styles.ad, isDisabled ? styles.disabled : '')} onClick={onClick}>
                <p className={styles.name}>
                    <span className={classNames(styles.bankIcon)}>
                        <i className={'ri-bank-line'} />
                    </span>
                    <span>
                        <span>
                            <span className={styles.bankName}>{bank.name}</span>
                        </span>
                        <span className={styles.capital}>
                            {/*CurrencyOutput.formatNoCode((bank?.totalAssets ?? 0) / 1000, 1)} mrd. i forvaltningskapital*/}
                        </span>
                    </span>
                </p>
                <div className={styles.interestgroup}>
                    <p className={styles.interest}>{InterestOutput(ad.interest, 2)}</p>
                    <Box
                        sx={{
                            backgroundColor: getProductType(ad.productId) === 'FIXED' ? SUNSET_ORANGE[50] : PURPLE[50],
                            color: getProductType(ad.productId) === 'FIXED' ? SUNSET_ORANGE[800] : PURPLE[500],
                            py: 0.6,
                            px: 1,
                        }}
                    >
                        {getProductType(ad.productId) === 'FIXED' ? (
                            <Typography px={1} fontSize={'1.4rem'} fontWeight={'600'}>
                                Fastrente
                            </Typography>
                        ) : (
                            <Stack direction='row' spacing={1} alignItems={'center'}>
                                <Typography fontSize={'1.2rem'} fontWeight={'600'}>
                                    NIBOR3M
                                </Typography>
                                <Divider sx={{ borderColor: PURPLE[100], height: '1.8rem' }} orientation='vertical' />
                                <Typography fontSize={'1.4rem'} fontWeight={'700'}>
                                    {InterestOutput(ad.interest, 2)}
                                </Typography>
                            </Stack>
                        )}
                    </Box>
                </div>
                <div className={styles.productInfo}>
                    <div className={styles.constraints}>
                        <React.Fragment>
                            <span className={styles.contstraints__title}>Beløpsgrenser</span>
                            <span className={styles.constraint__value}>
                                <span>
                                    {ad.min}–{ad.max} millioner
                                </span>
                            </span>
                        </React.Fragment>
                    </div>
                    {getProductName(parseInt(ad.productId))}
                </div>
                {message && (
                    <p
                        className={classNames(
                            styles.message,
                            messageType === 3 ? styles.message__alert : '',
                            messageType === 1 ? styles.message__new : '',
                            messageType === 4 ? styles.message__special : ''
                        )}
                    >
                        <span className={classNames(styles.bl, styles.corneredAngle)}></span>
                        <span className={styles.messageText}>
                            {getIcon(messageType) && <i className={getIcon(messageType) ?? ''} />}
                            <span className={styles.text}>{message}</span>
                        </span>
                    </p>
                )}
            </Box>
            {isDisabled && (
                <Alert
                    severity='warning'
                    icon={<i className='ri-stop-circle-line' />}
                    sx={{
                        position: 'absolute',
                        bottom: '0',
                        width: '100%',
                        fontSize: '1.2rem',
                        height: '4rem',
                        borderRadius: '0',
                        borderTop: '1px solid rgba(0,0,0,0.05)',
                        py: '0',
                        '& .MuiAlert-icon': {
                            fontSize: '1.6rem',
                            marginRight: '0.5rem',
                            lineHeight: 'none',
                        },
                    }}
                >
                    Fulltegnet
                </Alert>
            )}
        </ButtonBase>
    )
}
