import read from "./config";
import jwt_decode from 'jwt-decode';

export const isSessionAuthenticated = async () => {
    // Using tokens to authenticate across domains
    // First generate token in marketplace domain
    const getOptions = {
        headers: {'Accept': 'application/json',},
        credentials: 'include',
    };
    const tokenResponse = await fetch(read().marketplaceBaseUrl + '/api/session/token', getOptions);
    const token = await tokenResponse.json();

    // Then post token to dashboard domain
    const postOptions = {
        headers: {'Content-type': 'application/json',},
        method: 'POST',
        body: token.token
    };
    await fetch('/api/session/token', postOptions);

    // Fetching session in dashboard domain
    const sessionResponse = await fetch('/api/session', getOptions);
    const session = await sessionResponse.json();

    return session.authenticated
};

export const isAuthorized = (dashboard, decodedJwt, forOrg) => {
    if (dashboard) {
        switch (dashboard.authorization) {
            case 'depositor':
                return decodedJwt?.organisationType === 'DEPOSITOR' && forOrg === decodedJwt?.organisationId

            case 'bank':
                return decodedJwt?.organisationType === 'BANK' && forOrg === decodedJwt?.organisationId

            case 'partner':
                return decodedJwt?.organisationType === 'PARTNER' && forOrg === decodedJwt?.organisationId

            default:
                return decodedJwt?.organisationType === 'FIXRATE'
        }
    }
    return true;
};

export const generateJwt = async (requestId) => {
    const getOptions = {
        method: 'POST',
        headers: {'Accept': 'text/plain'},
        credentials: 'include',
    };
    let url = '/api/session/jwt/issue?client=fixrate-dashboard&role=dashboard&organisationType=FIXRATE'
    url = requestId ? url + '&requestId=' + requestId : url;
    const jwtResponse = await fetch(read().marketplaceBaseUrl + url, getOptions);
    return await jwtResponse.text();
};

export const decodeJwt = (jwtToken) => {
    return jwt_decode(jwtToken);
};
